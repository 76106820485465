<template>
	<div style="padding: 20px">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="会员卡名称/订单号">
					<el-input v-model="searchForm.keyword" placeholder="请输入查询内容"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>

				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button type="primary" @click="dialogFormVisible = true">会员卡采购</el-button>
					<!-- 	<el-button>导出</el-button> -->
					<!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="name" label="会员卡名称"></el-table-column>
			<el-table-column prop="num" label="数量"></el-table-column>
			<el-table-column prop="send_num" label="赠送数量"></el-table-column>
			<el-table-column prop="money" label="购买总价"></el-table-column>
			<el-table-column prop="buy_type" label="采购来源">
                <!-- 平台采购、自行采购 -->
                <template slot-scope="{row}">
                    <div v-if="row.buy_type == 0">自行采购</div>
                    <div v-if="row.buy_type == 1">平台采购</div>
                </template>
            </el-table-column>
			<el-table-column prop="created_at" label="订单创建时间"></el-table-column>
			<el-table-column prop="out_trade_no" label="订单号"></el-table-column>
			<!-- 	<el-table-column prop="agent" label="订单支付时间"></el-table-column> -->
			<!-- 	<el-table-column label="查看购买用户">
				<template slot-scope="scope">
					<el-button type="primary" @click="toGetUser(scope.row)">查看购买用户</el-button>
				</template>
			</el-table-column> -->

		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>

		<!-- 弹框 -->
		<el-dialog :visible.sync="dialogFormVisible" :modal="false">
			<!-- 	<el-card class="box-card"> -->
			<ul class="msg-box">
				<li>
					<h4>我要采购</h4>
				</li>
				<li>
					<h4 style="margin-bottom: 15px;">会员卡类型</h4>
					<el-select placeholder="全部" v-model="cardName" @change="changeClassify">
						<el-option v-for="item in classifyList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>

				</li>

				<li>
					<h4 style="margin-bottom: 15px;">会员卡权益</h4>
					<el-table :data="tableData1" height="250" border>
						<el-table-column prop="name" label="权益名称" width="180">
						</el-table-column>
						<el-table-column prop="price" label="价格">
						</el-table-column>
						<el-table-column prop="number" label="数量">
						</el-table-column>
					</el-table>


					<h4 style="margin-bottom: 15px;">优惠券</h4>
					<el-table :data="tableData2" height="250" border>
						<el-table-column prop="name" label="优惠券名称" width="180">
						</el-table-column>

						<el-table-column prop="num" label="数量">
						</el-table-column>
					</el-table>


				</li>



				<li>
					<h4 style="margin-bottom: 15px;color: red;">原价:{{yPirce}}</h4>

					<h4 style="margin-bottom: 15px;color: red;">折扣后单价:{{discount_money}}</h4>

					<h4 style="margin-bottom: 15px;">采购数量</h4>
					<el-input-number v-model="num" :step="10" :min="10" @change="handleChange" :precision="0">
					</el-input-number>
					<div style="padding-top: 20px;color: red;">赠送{{sNum}}个会员卡</div>
					<div style="padding-top: 20px;color: red;">合计{{money}}元</div>

				</li>

				<li>
					<h4 style="margin-bottom: 15px;">支付方式</h4>
					<el-radio-group v-model="paymentType" @change="paymentTypeChange">
						<el-radio border :label="''+ 'wechat'">微信</el-radio>
						<el-radio border :label="''+ 'alipay'">支付宝</el-radio>
					</el-radio-group>
				</li>

				<li v-if="erwema!=''">
					<h4 style="margin-bottom: 15px;">支付二维码</h4>
					<div style="width: 200px;height: 200px;"><img style="width: 100%;height: 100%;" :src="erwema"
							alt=""></div>
				</li> 


			</ul>
			<div style="text-align: center; margin-top: 30px;">
				<el-button type="primary" @click="surePay">确认支付</el-button>
			</div>
			<!-- </el-card> -->
		</el-dialog>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				pageSizes: [],
				searchForm: {
					keyword: '', // 否 string 用于搜索的关键字
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
				//弹框
				dialogFormVisible: false,

				amountVal: '',
				disabled: false,
				paymentType: "wechat",
				//充值参数
				// rechargeParams: {
				// 	"totalAmt": '', //金额
				// 	"paymentType": "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
				// 	"transType": "0" //交易类型[0:充值,1:消费]
				// },
				num: 20,
				sNum: 1,

				classifyList: [],
				cardName: "",
				money: "",
				erwema: "",
				out_trade_no: "",
				time: "",

				tableData1: [],
				tableData2: [],
				yPirce: 0,
				discount_money: 0


			};
		},
		mounted() {
			// 查询数据
			this.search()
			//会员卡类型
			this.hYCList()

		},
		methods: {
			//会员卡类型
			hYCList() {
				this.$api.system.hYList().then(res => {
					this.classifyList = res.data.data
					this.cardName = this.classifyList[0].id
					//总价格
					this.calculate()
					//获取会员卡详情
					this.getActInfo()
				})
			},
			//获取会员详情
			getActInfo() {
				this.$api.system.getActInfo({
					id: this.cardName
				}).then(res => {
					// console.log("11111")
					console.log(res.data)
					this.tableData1 = res.data.items
					this.tableData2 = res.data.coupon
					this.yPirce = res.data.price
					this.discount_money = res.data.discount_money

				})
			},

			//计算
			calculate() {
				this.$api.system.calculate({
					id: this.cardName,
					num: this.num
				}).then(res => {
					console.log(res.data)
					this.sNum = res.data.send_num
					this.money = res.data.price


				})
			},
			changeClassify(val) {
				console.log(val)
				this.calculate()
				//获取会员卡详情
				this.getActInfo()
				this.erwema = ""
				console.log(this.erwema)
				this.out_trade_no = "",
				console.log(this.out_trade_no)
				clearInterval(this.timer)
				this.timer = null
			},

			search() {
				this.loading = true;
				this.$api.system.payList(this.searchForm).then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
					this.pageSizes = res.data.per_page.toString().split()
					this.searchForm.page_size = res.data.per_page
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},

			exportMessage() {
				this.$api.table.consultExport({
					search: this.searchForm.search,
				}).then(res => {
					console.log(res)
					let a = document.createElement('a');
					//ArrayBuffer 转为 Blob
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					console.log(objectUrl)
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '新客咨询.xls');
					a.click();
				})
			},

			//采购数量
			handleChange(value) {
				console.log(value)
				this.changeClassify()
				// if (value % 10 == 0) {
				// 	this.sNum = value / 10
				// } else if (value % 10 != 0) {
				// 	this.sNum = parseInt(value / 10)
				// } else {
				// 	this.sNum = 1
				// }
			},

			//跳转用户购买详情
			toGetUser(item) {
				console.log(item)
				this.$router.push(`/memberRecharge/user/${item.id}`);
			},


			//支付方式
			paymentTypeChange(val) {
				this.paymentType = val
				console.log(val)

			},
			//确认支付
			surePay() {
				this.erwema = ""
				this.$api.system.pay({
					id: this.cardName,
					pay_type: this.paymentType,
					num: this.num
				}).then(res => {

					if (this.paymentType == "alipay") {
						// const div = document.createElement('div')
						// div.innerHTML = res
						// document.body.appendChild(div)

						// console.log(div)

						// document.forms[0].acceptCharset = 'UTF-8'
						// document.forms[0].submit()

						const payDiv = document.getElementById('payDiv');
						if (payDiv) {
							document.body.removeChild(payDiv);
						}
						const div = document.createElement('div');
						div.id = 'payDiv';
						div.innerHTML = res;
						console.log(res)
						document.body.appendChild(div);
						document.getElementById('payDiv').getElementsByTagName('form')[0].submit();


					} else {

						this.erwema = res.data.pay_qrcode
						console.log("1111")
						console.log(this.erwema)
						this.out_trade_no = res.data.out_trade_no
						console.log(this.out_trade_no)
						//定时查询
						this.timer = setInterval(() => {
							this.queryIsPay()
						}, 3000)
					}

					// serveApi.payZFB(obj).then(res => {
					// 	const div = document.createElement('div')
					// 	div.innerHTML = res
					// 	document.body.appendChild(div)
					// 	document.forms[0].acceptCharset = 'UTF-8'
					// 	document.forms[0].submit()

				})
			},

			//查询是否支付成功
			queryIsPay() {
				this.$api.system.loopPay({
					out_trade_no: this.out_trade_no
				}).then(res => {
					console.log(res)
					if (res.data.status == 1) {
						clearInterval(this.timer)
						this.timer = null
						this.$message.success('购买成功')
						this.erwema = "";
						this.dialogFormVisible = false
						this.search()
					}

				})
			},

		},

		// 关闭弹窗清除轮询
		closeWXpay() {
			clearInterval(this.timer)
			this.timer = ""
		}

	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}

	/* 信息列表样式 */
	.msg-box>li {
		list-style: none;
		border-bottom: 1px solid #c5c5c5;
		padding: 20px 10px;
	}
</style>
